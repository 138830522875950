import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var renderNote = function renderNote(ctx, node) {
  var _node$children;

  var _node$box = node.box,
      top = _node$box.top,
      left = _node$box.left;
  var value = (node === null || node === void 0 ? void 0 : (_node$children = node.children) === null || _node$children === void 0 ? void 0 : _node$children[0].value) || "";
  ctx.note(left, top, 0, 0, value);
  return node;
};

var _default = R.curryN(2, renderNote);

exports.default = _default;
export default exports;