import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda); // TODO: Implement using only matrices to support skew and even more operations than css.


var applySingleTransformation = function applySingleTransformation(ctx, transform, origin) {
  var operation = transform.operation,
      value = transform.value;

  switch (operation) {
    case "scale":
      {
        var scaleX = value[0],
            scaleY = value[1];
        ctx.scale(scaleX, scaleY, {
          origin: origin
        });
        break;
      }

    case "rotate":
      {
        var angle = value[0];
        ctx.rotate(angle, {
          origin: origin
        });
        break;
      }

    case "translate":
      {
        var x = value[0],
            y = value[1];
        ctx.translate(x, y, {
          origin: origin
        });
        break;
      }

    case "matrix":
      {
        ctx.transform.apply(ctx, value);
        break;
      }

    default:
      {
        console.error("Transform operation: '" + operation + "' doesn't supported");
      }
  }
};

var applyTransformations = function applyTransformations(ctx, node) {
  if (!node.origin) return node;
  var origin = [node.origin.left, node.origin.top];
  var operations = node.style && node.style.transform || [];
  operations.forEach(function (operation) {
    applySingleTransformation(ctx, operation, origin);
  });
  return node;
};

var _default = R.curryN(2, applyTransformations);

exports.default = _default;
export default exports;