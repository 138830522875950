import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _renderNode2 from "./primitives/renderNode";
import _addMetadata2 from "./operations/addMetadata";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _renderNode = _interopRequireDefault(_renderNode2);

var _addMetadata = _interopRequireDefault(_addMetadata2);

var renderDocument = function renderDocument(ctx) {
  return function (doc) {
    var pages = doc.children || [];
    pages.forEach((0, _renderNode.default)(ctx));
  };
};

var render = function render(ctx, doc) {
  (0, _addMetadata.default)(ctx)(doc);
  renderDocument(ctx)(doc);
  ctx.end();
  return ctx;
};

var _default = render;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;