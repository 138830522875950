import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _renderEllipse2 from "./renderEllipse";
var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _renderEllipse = _renderEllipse2;

var getProp = function getProp(p, v) {
  return R.path(["props", p], v);
};

var renderCircle = function renderCircle(ctx, node) {
  var cx = getProp("cx", node);
  var cy = getProp("cy", node);
  var r = getProp("r", node);
  (0, _renderEllipse.drawEllipse)(ctx, cx, cy, r, r);
  return node;
};

var _default = R.curryN(2, renderCircle);

exports.default = _default;
export default exports;