import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _extends4 from "@babel/runtime/helpers/extends";

var _extends = "default" in _extends4 ? _extends4.default : _extends4;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _extends3 = _interopRequireDefault(_extends);

var R = _interopRequireWildcard(_ramda);

var availableMethods = ["dash", "clip", "save", "path", "fill", "font", "text", "rect", "scale", "moveTo", "lineTo", "stroke", "rotate", "circle", "lineCap", "opacity", "ellipse", "polygon", "restore", "lineJoin", "fontSize", "fillColor", "lineWidth", "translate", "miterLimit", "strokeColor", "fillOpacity", "roundedRect", "fillAndStroke", "strokeOpacity", "bezierCurveTo", "quadraticCurveTo", "linearGradient", "radialGradient"];

var painter = function painter(ctx) {
  var p = availableMethods.reduce(function (acc, prop) {
    var _extends2;

    return (0, _extends3.default)({}, acc, (_extends2 = {}, _extends2[prop] = function () {
      ctx[prop].apply(ctx, arguments);
      return p;
    }, _extends2));
  }, {});
  return p;
};

var defaultsZero = R.propOr(0);

var renderCanvas = function renderCanvas(ctx, node) {
  var _node$box = node.box,
      top = _node$box.top,
      left = _node$box.left,
      width = _node$box.width,
      height = _node$box.height;
  var paddingTop = defaultsZero("paddingTop", node.box);
  var paddingLeft = defaultsZero("paddingLeft", node.box);
  var paddingRight = defaultsZero("paddingRight", node.box);
  var paddingBottom = defaultsZero("paddingBottom", node.box);
  var availableWidth = width - paddingLeft - paddingRight;
  var availableHeight = height - paddingTop - paddingBottom;

  if (!availableWidth || !availableHeight) {
    console.warn("Canvas element has null width or height. Please provide valid values via the `style` prop in order to correctly render it.");
  }

  ctx.save().translate(left + paddingLeft, top + paddingTop);

  if (node.props.paint) {
    node.props.paint(painter(ctx), availableWidth, availableHeight);
  }

  ctx.restore();
  return node;
};

var _default = R.curryN(2, renderCanvas);

exports.default = _default;
export default exports;