import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var setDestination = function setDestination(ctx, node) {
  var _node$props;

  if ((_node$props = node.props) !== null && _node$props !== void 0 && _node$props.id) {
    ctx.addNamedDestination(node.props.id, "XYZ", null, node.box.top, null);
  }

  return node;
};

var _default = R.curryN(2, setDestination);

exports.default = _default;
export default exports;