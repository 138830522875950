import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = exports.drawEllipse = void 0;

var R = _interopRequireWildcard(_ramda);

var KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);

var getProp = function getProp(p, v) {
  return R.path(["props", p], v);
};

var drawEllipse = function drawEllipse(ctx, cx, cy, rx, ry) {
  var x = cx - rx;
  var y = cy - ry;
  var ox = rx * KAPPA;
  var oy = ry * KAPPA;
  var xe = x + rx * 2;
  var ye = y + ry * 2;
  var xm = x + rx;
  var ym = y + ry;
  ctx.moveTo(x, ym);
  ctx.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
  ctx.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
  ctx.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
  ctx.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
  ctx.closePath();
};

exports.drawEllipse = drawEllipse;

var renderEllipse = function renderEllipse(ctx, node) {
  var cx = getProp("cx", node);
  var cy = getProp("cy", node);
  var rx = getProp("rx", node);
  var ry = getProp("ry", node);
  drawEllipse(ctx, cx, cy, rx, ry);
  return node;
};

var _default = R.curryN(2, renderEllipse);

exports.default = _default;
export default exports;