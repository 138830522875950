import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _save2 from "../operations/save";
import _restore2 from "../operations/restore";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _save = _interopRequireDefault(_save2);

var _restore = _interopRequireDefault(_restore2);

var drawImage = function drawImage(ctx) {
  return function (node) {
    var _node$props = node.props,
        x = _node$props.x,
        y = _node$props.y;
    var _node$style = node.style,
        width = _node$style.width,
        height = _node$style.height,
        opacity = _node$style.opacity;
    var paddingTop = node.box.paddingLeft || 0;
    var paddingLeft = node.box.paddingLeft || 0;

    if (node.image.data) {
      if (width !== 0 && height !== 0) {
        ctx.fillOpacity(opacity || 1).image(node.image.data, x + paddingLeft, y + paddingTop, {
          width: width,
          height: height
        });
      } else {
        console.warn("Image with src '" + node.props.href + "' skipped due to invalid dimensions");
      }
    }

    return node;
  };
};

var renderImage = function renderImage(ctx, node) {
  R.compose((0, _restore.default)(ctx), drawImage(ctx), (0, _save.default)(ctx))(node);
  return node;
};

var _default = R.curryN(2, renderImage);

exports.default = _default;
export default exports;