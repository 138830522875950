import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _save2 from "../operations/save";
import _restore2 from "../operations/restore";
import _clipNode2 from "../operations/clipNode";
import _parseColor2 from "../utils/parseColor";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _save = _interopRequireDefault(_save2);

var _restore = _interopRequireDefault(_restore2);

var _clipNode = _interopRequireDefault(_clipNode2);

var _parseColor = _interopRequireDefault(_parseColor2);

var drawBackground = function drawBackground(ctx, node) {
  if (node.box && node.style.backgroundColor) {
    var _node$style;

    var _node$box = node.box,
        top = _node$box.top,
        left = _node$box.left,
        width = _node$box.width,
        height = _node$box.height;
    var color = (0, _parseColor.default)(node.style.backgroundColor);
    var opacity = R.defaultTo(color.opacity, (_node$style = node.style) === null || _node$style === void 0 ? void 0 : _node$style.opacity);
    ctx.fillOpacity(opacity).fillColor(color.value).rect(left, top, width, height).fill();
  }

  return node;
};

var renderBackground = function renderBackground(ctx, node) {
  var _node$style2;

  var hasBackground = !!node.box && !!((_node$style2 = node.style) !== null && _node$style2 !== void 0 && _node$style2.backgroundColor);

  if (hasBackground) {
    (0, _save.default)(ctx, node);
    (0, _clipNode.default)(ctx, node);
    drawBackground(ctx, node);
    (0, _restore.default)(ctx, node);
  }

  return node;
};

var _default = R.curryN(2, renderBackground);

exports.default = _default;
export default exports;