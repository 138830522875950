import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _save2 from "../operations/save";
import _restore2 from "../operations/restore";
import _clipNode2 from "../operations/clipNode";
import _resolveObjectFit3 from "../utils/resolveObjectFit";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _save = _interopRequireDefault(_save2);

var _restore = _interopRequireDefault(_restore2);

var _clipNode = _interopRequireDefault(_clipNode2);

var _resolveObjectFit2 = _interopRequireDefault(_resolveObjectFit3);

var drawImage = function drawImage(ctx) {
  return function (node) {
    var _node$style, _node$style2, _node$style3, _node$style4;

    var _node$box = node.box,
        left = _node$box.left,
        top = _node$box.top;
    var opacity = (_node$style = node.style) === null || _node$style === void 0 ? void 0 : _node$style.opacity;
    var objectFit = (_node$style2 = node.style) === null || _node$style2 === void 0 ? void 0 : _node$style2.objectFit;
    var objectPositionX = (_node$style3 = node.style) === null || _node$style3 === void 0 ? void 0 : _node$style3.objectPositionX;
    var objectPositionY = (_node$style4 = node.style) === null || _node$style4 === void 0 ? void 0 : _node$style4.objectPositionY;
    var paddingTop = node.box.paddingLeft || 0;
    var paddingRight = node.box.paddingRight || 0;
    var paddingBottom = node.box.paddingBottom || 0;
    var paddingLeft = node.box.paddingLeft || 0;

    var _resolveObjectFit = (0, _resolveObjectFit2.default)(objectFit, node.box.width - paddingLeft - paddingRight, node.box.height - paddingTop - paddingBottom, node.image.width, node.image.height, objectPositionX, objectPositionY),
        width = _resolveObjectFit.width,
        height = _resolveObjectFit.height,
        xOffset = _resolveObjectFit.xOffset,
        yOffset = _resolveObjectFit.yOffset;

    if (node.image.data) {
      if (width !== 0 && height !== 0) {
        ctx.fillOpacity(opacity || 1).image(node.image.data, left + paddingLeft + xOffset, top + paddingTop + yOffset, {
          width: width,
          height: height
        });
      } else {
        console.warn("Image with src '" + node.props.src + "' skipped due to invalid dimensions");
      }
    }

    return node;
  };
};

var renderImage = function renderImage(ctx, node) {
  R.compose((0, _restore.default)(ctx), drawImage(ctx), (0, _clipNode.default)(ctx), (0, _save.default)(ctx))(node);
  return node;
};

var _default = R.curryN(2, renderImage);

exports.default = _default;
export default exports;