import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var getProp = function getProp(p, v) {
  return R.path(["props", p], v);
};

var renderLine = function renderLine(ctx) {
  return function (node) {
    var x1 = getProp("x1", node);
    var y1 = getProp("y1", node);
    var x2 = getProp("x2", node);
    var y2 = getProp("y2", node);
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    return node;
  };
};

var _default = renderLine;
exports.default = _default;
export default exports;