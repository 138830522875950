import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _parsePoints2 from "../svg/parsePoints";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = exports.drawPolyline = void 0;

var R = _interopRequireWildcard(_ramda);

var _parsePoints = _interopRequireDefault(_parsePoints2);

var drawPolyline = function drawPolyline(ctx) {
  return function (points) {
    if (points.length > 0) {
      ctx.moveTo(points[0][0], points[0][1]);
      points.slice(1).forEach(function (p) {
        return ctx.lineTo(p[0], p[1]);
      });
    }
  };
};

exports.drawPolyline = drawPolyline;

var renderPolyline = function renderPolyline(ctx) {
  return R.tap(R.compose(drawPolyline(ctx), _parsePoints.default, R.pathOr("", ["props", "points"])));
};

var _default = renderPolyline;
exports.default = _default;
export default exports;