import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _renderSvg2 from "./renderSvg";
import _renderText2 from "./renderText";
import _renderPage2 from "./renderPage";
import _renderNote2 from "./renderNote";
import _renderImage2 from "./renderImage";
import _renderDebug2 from "./renderDebug";
import _renderCanvas2 from "./renderCanvas";
import _renderBorders2 from "./renderBorders";
import _renderBackground2 from "./renderBackground";
import _isSvg2 from "../utils/isSvg";
import _isLink2 from "../utils/isLink";
import _isPage2 from "../utils/isPage";
import _isNote2 from "../utils/isNote";
import _isText2 from "../utils/isText";
import _isImage2 from "../utils/isImage";
import _isCanvas2 from "../utils/isCanvas";
import _save2 from "../operations/save";
import _setLink2 from "../operations/setLink";
import _restore2 from "../operations/restore";
import _clipNode2 from "../operations/clipNode";
import _transform2 from "../operations/transform";
import _setDestination2 from "../operations/setDestination";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _renderSvg = _interopRequireDefault(_renderSvg2);

var _renderText = _interopRequireDefault(_renderText2);

var _renderPage = _interopRequireDefault(_renderPage2);

var _renderNote = _interopRequireDefault(_renderNote2);

var _renderImage = _interopRequireDefault(_renderImage2);

var _renderDebug = _interopRequireDefault(_renderDebug2);

var _renderCanvas = _interopRequireDefault(_renderCanvas2);

var _renderBorders = _interopRequireDefault(_renderBorders2);

var _renderBackground = _interopRequireDefault(_renderBackground2);

var _isSvg = _interopRequireDefault(_isSvg2);

var _isLink = _interopRequireDefault(_isLink2);

var _isPage = _interopRequireDefault(_isPage2);

var _isNote = _interopRequireDefault(_isNote2);

var _isText = _interopRequireDefault(_isText2);

var _isImage = _interopRequireDefault(_isImage2);

var _isCanvas = _interopRequireDefault(_isCanvas2);

var _save = _interopRequireDefault(_save2);

var _setLink = _interopRequireDefault(_setLink2);

var _restore = _interopRequireDefault(_restore2);

var _clipNode = _interopRequireDefault(_clipNode2);

var _transform = _interopRequireDefault(_transform2);

var _setDestination = _interopRequireDefault(_setDestination2);

var shouldRenderChildren = function shouldRenderChildren(v) {
  return !(0, _isText.default)(v) && !(0, _isSvg.default)(v);
};

var isOverflowHidden = R.pathEq(["style", "overflow"], "hidden");

var renderChildren = function renderChildren(ctx) {
  return function (node) {
    (0, _save.default)(ctx, node);

    if (node.box) {
      ctx.translate(node.box.left, node.box.top);
    }

    var children = node.children || [];
    var renderChild = renderNode(ctx);
    children.forEach(renderChild);
    (0, _restore.default)(ctx, node);
    return node;
  };
};

var renderNode = function renderNode(ctx) {
  return function (node) {
    return R.compose((0, _restore.default)(ctx), (0, _renderDebug.default)(ctx), (0, _setDestination.default)(ctx), R.when(shouldRenderChildren, renderChildren(ctx)), R.when(_isLink.default, (0, _setLink.default)(ctx)), R.cond([[_isText.default, (0, _renderText.default)(ctx)], [_isNote.default, (0, _renderNote.default)(ctx)], [_isImage.default, (0, _renderImage.default)(ctx)], [_isCanvas.default, (0, _renderCanvas.default)(ctx)], [_isSvg.default, (0, _renderSvg.default)(ctx)], [R.T, R.identity]]), (0, _renderBorders.default)(ctx), (0, _renderBackground.default)(ctx), (0, _transform.default)(ctx), R.when(isOverflowHidden, (0, _clipNode.default)(ctx)), (0, _save.default)(ctx), R.when(_isPage.default, (0, _renderPage.default)(ctx)))(node);
  };
};

var _default = renderNode;
exports.default = _default;
export default exports;