import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _colorString3 from "color-string";

var _colorString2 = "default" in _colorString3 ? _colorString3.default : _colorString3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _colorString = _interopRequireDefault(_colorString2);

var parseColor = function parseColor(hex) {
  var parsed = _colorString.default.get(hex);

  var value = _colorString.default.to.hex(parsed.value.slice(0, 3));

  var opacity = parsed.value[3];
  return {
    value: value,
    opacity: opacity
  };
};

var _default = parseColor;
exports.default = _default;
export default exports;